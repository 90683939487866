<template>
  <component :is="configData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="configData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching country data
      </h4>
      <div class="alert-body">
        No country found with this country id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'countries'}"
        >
          Countries List
        </b-link>
        for other countries.
      </div>
    </b-alert>
    <b-form @submit.prevent="onUpdate">
      <template>
        <b-col md="12" style="display: inline-block">
          <b-form-group
              :label="'Privacy Policy'"
              :label-for="'privacy-policy'"
          >
            <ckeditor :editor="editor" :config="editorConfig" style="height: 200px" :id="'en'" rows="10" v-model="configData.content"></ckeditor>
          </b-form-group>

        </b-col>
      </template>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Cleave from 'vue-cleave-component'
import privacyPolicyStoreModule from '../privacyPolicyStoreModule'
import {BAlert, BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BLink, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import ClassicEditor from '../../../../ckeditor5-custom';
import * as UploadAdapter from '../../../../src/UploadAdapter';
export default {
  components: {
    BCard,
    ClassicEditor,
    Cleave,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    quillEditor,
    BButton
  },
  directives: {
    Ripple,
  },
  created() {
    UploadAdapter.eventBus.$on('startedUploading', (data) => {
      this.uploadingImage = true
    })
    UploadAdapter.eventBus.$on('endedUploading', (data) => {
      this.uploadingImage = false
    })
  },
  data(){
    return{
      content: null,
      editor: ClassicEditor,
      uploadingImage: false,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent' ,'|','insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo','|','alignment', 'code', 'imageResize', 'codeBlock', 'findAndReplace', 'fontColor', 'fontBackgroundColor', 'fontSize','fontFamily', 'highlight', 'htmlEmbed', 'removeFormat', 'underline' ],
        table: {
          toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
          this.uploadingImage = true
          return new UploadAdapter.default(loader);
        }}],
        language: 'nl',
      },
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
    }
  },
  setup() {
    const blankConfigData = {
      content: '',
      ios_version: '',
    }
    const countryFlagId = ref(null);
    const toast = useToast()

    const configData = ref(JSON.parse(JSON.stringify(blankConfigData)))
    const snowOption = ref({
      theme: 'snow',
    })

    const PRIVACY_POLICY_STORE_MODULE_NAME = 'privacy-policy'

    // Register module
    if (!store.hasModule(PRIVACY_POLICY_STORE_MODULE_NAME)) store.registerModule(PRIVACY_POLICY_STORE_MODULE_NAME, privacyPolicyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRIVACY_POLICY_STORE_MODULE_NAME)) store.unregisterModule(PRIVACY_POLICY_STORE_MODULE_NAME)
    })
    store.dispatch('privacy-policy/fetchPrivacyPolicy', {id: router.currentRoute.params.id})
        .then(response => {
          console.log(response.data.data)
          configData.value.content = response.data.data.content
        })
        .catch(error => {
          if (error.response.status === 404) {
            configData.value = undefined
          }
        })

    const onUpdate = () => {
      // console.log(configData.value)
      store.dispatch('privacy-policy/updatePrivacyPolicy', {
        data: configData.value
      })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Privacy Policy Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    return {
      onUpdate,
      configData,
      snowOption,
      countryFlagId
    }
  },
}
</script>

<style>

</style>
